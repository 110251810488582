<template>
	<v-container id="crud" fluid tag="section">
		<v-card>
			<v-toolbar flat color="white">
				<v-toolbar-title>{{ title }}</v-toolbar-title>
			</v-toolbar>

			<v-data-table
				:headers="showHeaders"
				:items="filteredData"
				:search="search"
				sort-by="id"
				sort-desc
				class="elevation-1"
			>
				<template v-slot:[`item.activo`]="{ item }">
					<div
						:class="$getCss(item.activo, 'activo')"
						style="width: 100px"
						dark
					>
						{{ $getValue(item.activo, "activo") }}
					</div>
				</template>

				<template v-slot:[`item.archivo`]="{ item }">
					<div style="width: 100px" dark>
						{{ item.archivo ? JSON.parse(item.archivo).name : "" }}
					</div>
				</template>
				<template v-slot:[`header.archivo`]="{ header }">
					<filter-component
						v-bind:header="{ header }"
						v-bind:filters="filters"
					></filter-component>
				</template>
				<template v-slot:[`header.entidad`]="{ header }">
					<filter-component
						v-bind:header="{ header }"
						v-bind:filters="filters"
					></filter-component>
				</template>
				<template v-slot:[`header.visible`]="{ header }">
					<filter-component
						v-bind:header="{ header }"
						v-bind:filters="filters"
					></filter-component>
				</template>

				<template v-slot:top>
					<v-toolbar flat color="white" class="pr-4">
						<v-row>
							<v-col sm="6" class="pt-20">
								<v-text-field
									v-model="search"
									append-icon="mdi-magnify"
									:label="'Busqueda de ' + title"
									single-line
									hide-details
								></v-text-field>
							</v-col>
							<v-col sm="2"></v-col>
							<v-col sm="3" class="">
								<v-autocomplete
									v-model="selectedHeaders"
									:items="headers"
									label="Columnas Visibles"
									multiple
									return-object
								>
									<template v-slot:selection="{ item, index }">
										<v-chip v-if="index < 2">
											<span>{{ item.text }}</span>
										</v-chip>
										<span v-if="index === 2" class="grey--text caption"
											>(otras {{ selectedHeaders.length - 2 }}+)</span
										>
									</template>
								</v-autocomplete>
							</v-col>
							<v-col sm="1">
								<v-dialog v-model="dialog" max-width="50%" persistent>
									<template
										v-if="$can([titlePerms + '_create'])"
										v-slot:activator="{ on, attrs }"
									>
										<v-btn
											:color="$cv('primary')"
											dark
											class="mx-2"
											fab
											v-bind="attrs"
											v-on="on"
											@click="vista = false"
											><v-icon blue>mdi-plus</v-icon></v-btn
										>
									</template>

									<v-card>
										<v-form ref="form" v-model="valid" lazy-validation>
											<v-toolbar dark :color="$cv('primary')">
												<v-btn icon dark @click="dialog = false">
													<v-icon>mdi-close</v-icon>
												</v-btn>
												<v-toolbar-title>{{ formTitle }}</v-toolbar-title>
												<v-spacer></v-spacer>
												<v-toolbar-items>
													<v-btn
														dark
														text
														@click="saveWithFile()"
														v-if="!vista"
														:disabled="!valid"
														>Guardar</v-btn
													>
												</v-toolbar-items>
											</v-toolbar>

											<v-card-text>
												<v-container>
													<v-row>
														<v-col cols="12" md="12" sm="12" class="py-0">
															<v-text-field
																prepend-inner-icon="mdi-card-account-details"
																v-model="editedItem.titulo"
																label="Titulo"
																:disabled="vista"
																:rules="[
																	$rulesRequerido,
																	$rulesAlfaNum,
																	$rulesMax500,
																]"
															></v-text-field>
														</v-col>
														<v-col cols="12" md="12" sm="12" class="py-0">
															<small class="text--info">
																Selecciona el archivo a subir. Los formatos
																permitidos son pdf, xlsx, docx.
															</small>
														</v-col>

														<v-col
															v-if="!editedItem.archivo.name"
															cols="12"
															md="12"
															sm="12"
															class="py-0"
														>
															<v-file-input
																chips
																v-model="editedItem.archivo"
																:rules="fileRules"
															></v-file-input>
														</v-col>
														<v-col
															v-else
															cols="12"
															md="12"
															sm="12"
															class="py-3"
														>
															<v-chip
																label
																color="default"
																close
																:disabled="vista"
																@click:close="removeFile(editedItem.id)"
															>
																{{ editedItem.archivo.name }}
															</v-chip>
														</v-col>
														<v-col cols="12" md="12" sm="12" class="py-0">
															<small>
																Selecciona los tipos de entidad que van a poder
																ver el archivo.
															</small>
														</v-col>
														<v-col cols="12" md="6" sm="12" class="py-0">
														</v-col>

														<v-col cols="12" md="12" sm="12" class="py-0">
															<v-autocomplete
																prepend-inner-icon="mdi-card-account-details"
																:items="tipo_entidades"
																item-value="id"
																item-text="nombre"
																label="Tipo Entidad"
																v-model="editedItem.tipo_entidades"
																:rules="[$rulesRequerido]"
																:disabled="vista"
																multiple
																return-object
															></v-autocomplete>
														</v-col>

														<v-col cols="12" md="12" sm="12" class="py-0">
															<v-switch
																v-model="editedItem.activo"
																label="Visible"
																color="primary"
																:value="visibleValue"
																:disabled="vista"
																hide-details
															></v-switch>
														</v-col>
													</v-row>
												</v-container>
											</v-card-text>
										</v-form>
									</v-card> </v-dialog
							></v-col>
						</v-row>
					</v-toolbar>
				</template>

				<template v-slot:[`item.tipo_entidades`]="{ item }">
					<v-chip
						v-for="(tag, i) in JSON.parse(item.tipo_entidades).slice(0, 4)"
						:key="i"
					>
						{{ tag.nombre }}
					</v-chip>

					<v-chip
						v-if="JSON.parse(item.tipo_entidades).length > 4"
						style="background-color: #b0bec5"
					>
						{{
							"(otros " + (JSON.parse(item.tipo_entidades).length - 4) + " +)"
						}}
					</v-chip>
				</template>
				<template v-slot:[`item.created_at`]="{ item }">
					{{ item.created_at }}
				</template>
				<template v-slot:[`item.actions`]="{ item }">
					<v-row class="">
						<v-col cols="6" md="6" sm="6" style="padding: 6px 0px 0px 0px">
							<v-menu
								bottom
								origin="center center"
								transition="scale-transition"
							>
								<template v-slot:activator="{ on, attrs }">
									<v-btn
										color="transparent elevation-0"
										dark
										small
										v-bind="attrs"
										v-on="on"
										min-width="30px"
									>
										<v-icon
											large
											color="blue-grey lighten-3"
											style="font-size: 30px"
										>
											mdi-dialpad
										</v-icon>
									</v-btn>
								</template>

								<v-list>
									<v-list-item
										@click="
											vista = false;
											editItem(item.id);
										"
										v-if="$can([titlePerms + '_update'])"
									>
										<v-list-item-icon class="mr-0"
											><v-icon
												small
												class="mr-2"
												:color="$cv('btnVista')"
												title="Editar"
											>
												mdi-pencil
											</v-icon> </v-list-item-icon
										><v-list-item-content>
											<v-list-item-title>Editar</v-list-item-title>
										</v-list-item-content>
									</v-list-item>
									<v-list-item
										@click="
											vista = true;
											editItem(item.id);
										"
										v-if="$can([titlePerms + '_read'])"
									>
										<v-list-item-icon class="mr-0"
											><v-icon
												small
												class="mr-2"
												:color="$cv('btnVista')"
												title="Ver"
											>
												mdi-eye-outline
											</v-icon> </v-list-item-icon
										><v-list-item-content>
											<v-list-item-title>Ver</v-list-item-title>
										</v-list-item-content>
									</v-list-item>
									<v-list-item
										@click="download(item)"
										v-if="$can([titlePerms + '_download'])"
									>
										<v-list-item-icon class="mr-0">
											<v-icon
												medium
												class="mr-2"
												color="green"
												title="Descargar"
											>
												mdi-download
											</v-icon>
										</v-list-item-icon>
										<v-list-item-content>
											<v-list-item-title>Ver Archivo</v-list-item-title>
										</v-list-item-content>
									</v-list-item>
									<v-list-item
										@click="download(item, true)"
										v-if="$can([titlePerms + '_download'])"
									>
										<v-list-item-icon class="mr-0">
											<v-icon
												medium
												class="mr-2"
												color="green"
												title="Descargar"
											>
												mdi-download
											</v-icon>
										</v-list-item-icon>
										<v-list-item-content>
											<v-list-item-title>Descargar Archivo</v-list-item-title>
										</v-list-item-content>
									</v-list-item>
									<v-list-item
										@click="$enabledItem(item)"
										v-if="$can([titlePerms + '_changestatus'])"
									>
										<v-list-item-icon class="mr-0">
											<v-icon
												medium
												class="mr-2"
												:color="
													item.activo == 1
														? $cv('btnInactivo')
														: $cv('btnActivo')
												"
												title="Activar/Inactivar"
											>
												{{
													item.activo == 1
														? "mdi-archive-arrow-down"
														: "mdi-archive-arrow-up"
												}}
											</v-icon>
										</v-list-item-icon>
										<v-list-item-content>
											<v-list-item-title>{{
												item.activo == 1
													? $cv("titleDesarchivar")
													: $cv("titleArchivar")
											}}</v-list-item-title>
										</v-list-item-content>
									</v-list-item>
									<!--<v-list-item
										v-if="$can([titlePerms + '_delete'])"
										@click="removeFile(item.id)"
									>
										<v-list-item-icon class="mr-0">
											<v-icon
												small
												class="mr-2"
												:color="$cv('btnEliminar')"
												title="Eliminar Archivo"
											>
												mdi-delete
											</v-icon>
										</v-list-item-icon>
										<v-list-item-content>
											<v-list-item-title>Eliminar Archivo</v-list-item-title>
										</v-list-item-content>
									</v-list-item>-->
									<v-list-item
										v-if="$can([titlePerms + '_delete'])"
										@click="deleteItem(item.id, item.titulo)"
									>
										<v-list-item-icon class="mr-0">
											<v-icon
												small
												class="mr-2"
												:color="$cv('btnEliminar')"
												title="Eliminar"
											>
												mdi-delete
											</v-icon>
										</v-list-item-icon>
										<v-list-item-content>
											<v-list-item-title>Eliminar</v-list-item-title>
										</v-list-item-content>
									</v-list-item>
								</v-list>
							</v-menu>
						</v-col>
					</v-row>
				</template>
			</v-data-table>
		</v-card>

		<v-snackbar
			v-model="snackbar"
			:bottom="true"
			:color="color"
			:timeout="timeout"
		>
			<div v-html="text"></div>

			<template v-slot:action="{ attrs }">
				<v-btn dark text v-bind="attrs" @click="snackbar = false">
					Cerrar
				</v-btn>
			</template>
		</v-snackbar>
		<vue-confirm-dialog></vue-confirm-dialog>
	</v-container>
</template>

<script>
	function title() {
		return "Archivos";
	}

	export default {
		data: (vm) => ({
			valid: true,
			_method: "PUT",
			autoGrow: true,
			rows: 1,
			title: title(),
			route: "archivos",
			fileRules: [(v) => !!v || "El archivo es requerido"],
			titlePerms: title().toLowerCase(),

			menu: false,
			dialog: false,
			showDialog: false,
			snackbar: false,

			visibleValue: 1,
			text: "Registro Insertado",
			color: "success",
			timeout: 4000,
			search: "",
			vista: false,
			headers: [
				{ text: "Acciones", value: "actions", sortable: false },
				{ text: "Titulo", value: "titulo" },
				{ text: "Archivo", value: "archivo" },
				{ text: "Tipo Entidades", value: "tipo_entidades" },
				{ text: "Tamaño (MB)", value: "tamanio" },
				{ text: "Fecha Publicación", value: "created_at" },
				{ text: "Visible", value: "activo" },
			],
			desserts: [],
			editedIndex: -1,
			editedItem: {
				titulo: "",
				archivo: {},
				tamanio: "",
				tipo_entidades: [],
				activo: 1,
			},
			defaultItem: {
				titulo: "",
				archivo: {},
				tamanio: "",
				tipo_entidades: [],
				activo: 1,
			},
			filters: {
				archivo: "",
				visible: "",
			},
			filterKey: [],
			selectedHeaders: [],
			tipo_entidades: [],
			estados: [
				{ id: 1, detalle: "Si" },
				{ id: 2, detalle: "No" },
			],
			fileOld: {},
		}),

		computed: {
			formTitle() {
				return this.editedIndex === -1
					? "Registrar " + this.title
					: this.vista == false
					? "Editar " + this.title
					: this.title;
			},
			filteredData() {
				return this.$filteredData().data !== undefined
					? this.$filteredData().data
					: this.$filteredData();
			},
			showHeaders() {
				return this.headers.filter((s) => this.selectedHeaders.includes(s));
			},
		},

		watch: {
			async dialog(val) {
				if (!val) {
					this.$close();
				} else {
					console.log(this.editedItem);
					if (this.editedItem.id) {
						this.editedIndex = this.editedItem.id;
					} else {
						this.editedIndex = -1;
					}
					this.editedItem.activo = this.editedItem.activo == "1" ? 1 : 0;
				}
			},
		},

		created() {
			this.$initialize(true);
			//this.frontSetGrid();
			if (this.$can([this.titlePerms + "_create"])) {
				this.$getData(["tipo_entidades"]);
			}
			this.selectedHeaders = this.headers;
		},

		methods: {
			formatDate(timestamp) {
				console.log(timestamp);
				if (timestamp === null) {
					return "";
				}
			},

			async editItem(id) {
				Swal.alertGetInfo("Obteniendo información <br><b></b>");

				this.editedIndex = id;
				await this.$axiosApi
					.getById(this.route, id)
					.then((r) => {
						this.editedItem = Object.assign({}, r.data.data);

						if (!this.editedItem.archivo.name) {
							this.editedItem.archivo.name = "";
						} else {
							this.fileOld = Object.assign({}, this.editedItem.archivo);
						}
						this.dialog = true;
						Swal.close();
					})
					.catch(function (error) {
						Swal.close();
						this.snackbar = true;
						this.text =
							"<p>Ocurrió un problema al recuperar el registro.<br><hr><b>Codigo:</b>" +
							error.data.code +
							" - <b>Error:</b> " +
							error.data.message +
							"</p>";
						this.color = "error";
					});
			},

			async saveWithFile() {
				let vm = this;

				if (this.$refs.form.validate() && this.valid) {
					const file = vm.editedItem.archivo;
					console.log(vm.editedItem.tipo_entidades.length);
					console.log(typeof vm.editedItem.tipo_entidades);
					console.log(typeof file);
					const maxFileSize = 5 * 1024 * 1024; // 2 MB en bytes

					var fd = new FormData();

					console.log(this.fileOld);
					if (this.fileOld.name != file.name) {
						if (file && file.name != "" && typeof file.name !== "undefined") {
							if (file.size > maxFileSize) {
								this.snackbar = true;
								this.text =
									"El tamaño del archivo debe ser menor o igual a 2 MB.";
								this.color = "error";
								return;
							}
							fd.append("archivo", file, file.name);
						} else {
							this.snackbar = true;
							this.text = "No se registro un archivo adjunto";
							this.color = "error";
							return;
						}
					}
					fd.append("titulo", this.editedItem.titulo);

					if (parseInt(vm.editedItem.tipo_entidades.length) > 0) {
						fd.append(
							"tipo_entidades",
							JSON.stringify(this.editedItem.tipo_entidades)
						);
					} else {
						this.snackbar = true;
						this.text = "Debe seleccionar al menos un tipo de entidad";
						this.color = "error";
						return;
					}

					fd.append("activo", this.editedItem.activo);
					let i = 0;

					if (this.editedIndex != -1) {
						Swal.alertGetInfo("Actualizando información");

						await this.$axiosApi
							.putFile(vm.route, fd, this.editedIndex)
							.then((r) => {
								vm.$respuesta(vm, r, 2);
								Swal.close();
							})
							.catch((e) => {
								vm.$respuesta(vm, e, 2, true);
								Swal.close();
							});
					} else {
						Swal.alertGetInfo("Registrando información");

						await this.$axiosApi
							.postFile(vm.route, fd)
							.then((r) => {
								vm.$respuesta(vm, r, 1);
								Swal.close();
							})
							.catch((e) => {
								vm.$respuesta(vm, e, 1, true);
								Swal.close();
							});
					}
				}
			},

			async removeFile(id) {
				let vm = this;

				if (id > 0) {
					Swal.fire({
						title: `Estas seguro que deseas eliminar el documento seleccionado?`,
						text: ``,
						icon: "warning",
						showCancelButton: true,
						confirmButtonColor: "#3085d6",
						cancelButtonColor: "#d33",
						confirmButtonText: "Confirmar",
						cancelButtonText: "Cancelar",
					}).then((result) => {
						if (result.isConfirmed) {
							Swal.alertGetInfo("Actualizando información");

							this.$axiosApi
								.delete("archivos_removefile", id)
								.then((r) => {
									this.editedItem.archivo = {};
									this.snackbar = true;
									this.text = "Archivo borrado con exito";
									this.color = "success";

									Swal.close();
									this.$initialize();
								})
								.catch((e) => {
									this.snackbar = true;
									this.text = "No se pudo borrar el archivo";
									this.color = "error";
									Swal.close();
								});
						}
					});
				} else {
					this.editedItem.archivo = {};
				}
			},

			filterByArchivo(item) {
				return this.$filterBy(item, "archivo");
			},
			filterByEntidad(item) {
				return this.$filterBy(item, "entidad");
			},
			filterByVisible(item) {
				return this.$filterBy(item, "visible");
			},
			async download(item, descargar = false) {
				console.log(item);
				if (!JSON.parse(item.archivo).name) {
					Swal.fire("Atención!", "No hay archivos cargados 1", "warning");
					return;
				}

				if (typeof item.archivo === "undefined") {
					Swal.fire("Atención!", "No hay archivos cargados 2", "warning");
					return;
				}

				await this.$axiosApi
					.getById("archivos_getfile", item.id)
					.then((response) => {
						const data = response.data;
						if (data.fileContent) {
							const binaryString = window.atob(data.fileContent);
							const binaryLen = binaryString.length;
							const bytes = new Uint8Array(binaryLen);
							for (let i = 0; i < binaryLen; i++) {
								bytes[i] = binaryString.charCodeAt(i);
							}
							var blob = new Blob([bytes], {
								type: JSON.parse(item.archivo).type,
							});
							var fileURL = window.URL.createObjectURL(blob, {
								oneTimeOnly: true,
							});

							var fileLink = document.createElement("a");
							fileLink.href = fileURL;
							//nueva pestaña
							if (descargar == true) {
								//descarga
								fileLink.setAttribute("download", JSON.parse(item.archivo).name);
							} else {
								fileLink.target = "_blank";
							}
							document.body.appendChild(fileLink);

							fileLink.click();
						} else {
							console.error("No se pudo obtener el archivo.");
							Swal.fire("Atención!", "No se pudo obtener el archivo.", "warning");
						}
					})
					.catch(console.error);
			},

			async deleteItem(id, nombre) {
				let vm = this;
				vm.$confirm({
					message: "¿Esta seguro que desea eliminar el documento seleccionado ?",
					button: {
						no: "Cancelar",
						yes: "Eliminar",
					},

					callback: async function (confirm) {
						if (confirm) {
							Swal.alertGetInfo("Eliminando Registro");

							await vm.$axiosApi
								.delete(vm.route, id)
								.then((r) => {
									vm.$respuesta(vm, r, 0);
								})
								.catch((e) => {
									vm.$respuesta(vm, e, 0, true);
								});
							Swal.close();
						}
					},
				});
			},
		},
		mounted() {
			//this.$hideMenu();
			//this.$getData(["tipo_tramites", "tipo_certificados", "tipo_vehiculos"]);
			console.log("Componente " + this.route + " creado");
		},
	};
</script>
<style scoped>
.text-fields-row {
	display: flex;
}
.x-btn-grid {
	flex: unset;
	background-color: bisque;
	margin: 0 0px;
}

/* Ajustar z-index de SweetAlert2 */
.swal2-container {
	z-index: 999999 !important;
}
.swal2-content {
	z-index: 999999 !important;
}
</style>
